(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';
var Promise = require('es6-promise-polyfill').Promise
module.exports = function(url) {
    var result = new Promise(function(resolve, reject) {
        var errorHandler = function() {
            reject(new Error("Couldn't load " + url + " . Response: " + xhr.status + " " + xhr.statusText));
        };
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.onload = function() {
            if (xhr.status >= 200 && xhr.status < 400) {
                var data = xhr.response;
                if (typeof data === 'string') {
                    data = JSON.parse(data);
                }
                resolve(data);
            } else {
                errorHandler();
            }
        }
        xhr.responseType = 'json';
        xhr.onerror = errorHandler;
        xhr.timeout = 5000;
        xhr.send();
    });
    return result;
}
},{"es6-promise-polyfill":5}],2:[function(require,module,exports){
'use strict';
var getJson = require('./getJson');
var Promise = require('es6-promise-polyfill').Promise
var stringsByLocaleAndKey = {};
var readyPromiseResolver;
var readyPromiseRejector;
var readyPromise = new Promise(function(resolve, reject) {
    readyPromiseResolver = resolve;
    readyPromiseRejector = reject;
});

module.exports = {
    init: init,
    get: get,
    ready: ready
};

function init(resourceFileLocations) {
    var promises = [];
    resourceFileLocations.forEach(function(resourceFileLocation) {
        promises.push(getJson(resourceFileLocation).then(function(result){
            var parts = resourceFileLocation.split('/');
            parts = parts[parts.length - 1].split('.');
            var locale = parts[parts.length - 2].toLocaleLowerCase();
            parts = locale.split('-');
            if (parts.length > 2) {
                locale = parts[0] + '-' + parts[1];
            } else if (parts.length === 2 && parts[1].length > 2) {
                locale = parts[0];
            }
            stringsByLocaleAndKey[locale] = result;
        }));
    });
    Promise.all(promises).then(function() {
        readyPromiseResolver();
    }).catch(function(err){
        readyPromiseRejector(err);
    });
}

function get(locale) {
    return stringsByLocaleAndKey[locale];
}

function ready() {
    return readyPromise;
}
},{"./getJson":1,"es6-promise-polyfill":5}],3:[function(require,module,exports){
(function (global,__dirname){
'use strict';
var resourceStringCache = require('./resourceStringCache');
var resourceFormatter = require('./resourceFormatter');
var fs = {};
var path = {};
var clientSideLibUrl;
if (typeof window === 'undefined') {
	if (global.Intl) {
		// if we had a version of node that had international support baked in, we wouldn't need this
		// could use intl-locales-supported module to detect this and polyfill conditionally if we had a list of locales we cared about
		var IntlPolyfill    = {};
		Intl.NumberFormat   = IntlPolyfill.NumberFormat;
		Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
	} else {
		global.Intl = {};
	}

	clientSideLibUrl = 'https://www.ancestrycdn.com' + JSON.parse(fs.readFileSync(path.join(__dirname,'../cdn-map.json'), 'utf8'))['ancestry-i18n.js'];
} else {
	window.ui = window.ui || {};
	window.ui.i18n = i18nFactory;
}

function i18nFactory(resourceDirectoryOrFiles, cdnMapperFunction) {
	resourceStringCache.init(resourceDirectoryOrFiles, cdnMapperFunction);
	return {
		ready: resourceStringCache.ready,
		get: resourceFormatter.get,
		getClientSideLib: getClientSideLib,
		getClientSideFiles: resourceStringCache.getClientSideFiles,
		registerFormatter: resourceFormatter.registerFormatter
	};
}

module.exports = i18nFactory;

function getClientSideLib() {
	return clientSideLibUrl;
}
}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},"/lib")
},{"./resourceFormatter":4,"./resourceStringCache":2}],4:[function(require,module,exports){
(function (global){
'use strict';

var resourceStringCache = require('./resourceStringCache');
var formatRegex = /{[^}{]+}/g;
var formatterSeparator = '|';
var numberFormatters = {};
var dateFormatters = {};
var formatters = {};

function registerFormatter(formatterName, formatter) {
    formatters[formatterName] = formatter;
}

registerFormatter("number", function(str, locale) {
    if (!numberFormatters[locale]) {
        numberFormatters[locale] = global.Intl ? new Intl.NumberFormat(locale) : null;
    }
    var numberFormat = numberFormatters[locale];
    var num = parseInt(str);
    try {
        return numberFormat ? numberFormat.format(num) : num.toLocaleString(locale);
    } catch (e) {
        return num.toLocaleString();
    }
});

registerFormatter("date", function(date, locale) {
    var options = {year:'numeric',day:'numeric',month:'short'};
    if (!dateFormatters[locale]) {
        dateFormatters[locale] = global.Intl ? new Intl.DateTimeFormat(locale,options) : null;
    }
    var dateFormat = dateFormatters[locale];
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    try {
        return dateFormat ? dateFormat.format(date) : date.toLocaleDateString(locale,options);
    } catch (e) {
        return date.toLocaleDateString();
    }
});

function getString(locale, key) {
    var normalizedLocale = locale.replace('_', '-').toLocaleLowerCase();
    var genericLocale = locale.split('-')[0];

    if (!resourceStringCache.get(normalizedLocale) && !resourceStringCache.get(genericLocale)) {
        throw new Error("Unknown locale specified: " + locale);
    }

    var str = (resourceStringCache.get(normalizedLocale) && resourceStringCache.get(normalizedLocale)[key]) ||
        (resourceStringCache.get(genericLocale) && resourceStringCache.get(genericLocale)[key]);

    if (!str) {
        throw new Error("Unknown key specified: " + key + " in locale: " + locale);
    }
    return str;
}

function get(key, locale, paramValuesObjOrFunc) {
    var str = getString(locale, key);

    var contextType = typeof (paramValuesObjOrFunc);
    if (contextType === 'function' || contextType == 'object') {
        var resultStr = '';
        var index = 0;
        var strLiteral;

        for (var curlyIndex = str.indexOf('{'); index < str.length && curlyIndex >= 0; curlyIndex = str.indexOf('{', curlyIndex)) {
            if (curlyIndex + 1 < str.length && str[curlyIndex + 1] === '{') {
                curlyIndex += 2;
                continue;
            }

            strLiteral = str.substring(index, curlyIndex).replace(/{{/g, '{').replace(/}}/g, '}');
            resultStr += (contextType === 'function') ? paramValuesObjOrFunc(strLiteral, 'literal') : strLiteral;
            var endCurly = str.indexOf('}', curlyIndex);
            if (endCurly === -1) {
                throw new Error("Unmatched curly brace for key \"" + key + "\" in locale \"" + locale + "\"");
            }
            var innerKey = str.substring(curlyIndex + 1, endCurly);
            var formatter;
            var parts = innerKey.split(formatterSeparator);
            if (parts.length > 1) {
                innerKey = parts[0];
                if (!formatters[parts[1]]) {
                    throw new Error('Unrecognized formatter: ' + parts[1] + ' in localized string "' + str + '" (key: ' + key + ', locale: ' + locale + ')');
                }
                formatter = formatters[parts[1]];
                var formatterForLocale = function(str) { return formatter(str, locale) };
            }
            var valueStr = (contextType === 'function') ? paramValuesObjOrFunc(innerKey, 'key', formatterForLocale) : paramValuesObjOrFunc[innerKey];
            if (contextType === 'object' && formatter) {
                valueStr = formatter(valueStr, locale);
            }
            if (!valueStr) {
                throw new Error("Unable to get value for sub key: " + innerKey + " for key: " + key + " in locale: " + locale);
            }

            resultStr += valueStr;
            index = endCurly + 1;
            curlyIndex = index;
        }

        if (index < str.length) {
            strLiteral = str.substring(index).replace(/{{/g, '{').replace(/}}/g, '}');
            resultStr += (contextType === 'function') ? paramValuesObjOrFunc(strLiteral, 'literal') : strLiteral;
        }

        return resultStr;
    }

    return str;
}

module.exports = {
    get: get,
    registerFormatter: registerFormatter
};

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"./resourceStringCache":2}],5:[function(require,module,exports){
(function (global){
(function(global){

//
// Check for native Promise and it has correct interface
//

var NativePromise = global['Promise'];
var nativePromiseSupported =
  NativePromise &&
  // Some of these methods are missing from
  // Firefox/Chrome experimental implementations
  'resolve' in NativePromise &&
  'reject' in NativePromise &&
  'all' in NativePromise &&
  'race' in NativePromise &&
  // Older version of the spec had a resolver object
  // as the arg rather than a function
  (function(){
    var resolve;
    new NativePromise(function(r){ resolve = r; });
    return typeof resolve === 'function';
  })();


//
// export if necessary
//

if (typeof exports !== 'undefined' && exports)
{
  // node.js
  exports.Promise = nativePromiseSupported ? NativePromise : Promise;
  exports.Polyfill = Promise;
}
else
{
  // AMD
  if (typeof define == 'function' && define.amd)
  {
    define(function(){
      return nativePromiseSupported ? NativePromise : Promise;
    });
  }
  else
  {
    // in browser add to global
    if (!nativePromiseSupported)
      global['Promise'] = Promise;
  }
}


//
// Polyfill
//

var PENDING = 'pending';
var SEALED = 'sealed';
var FULFILLED = 'fulfilled';
var REJECTED = 'rejected';
var NOOP = function(){};

function isArray(value) {
  return Object.prototype.toString.call(value) === '[object Array]';
}

// async calls
var asyncSetTimer = typeof setImmediate !== 'undefined' ? setImmediate : setTimeout;
var asyncQueue = [];
var asyncTimer;

function asyncFlush(){
  // run promise callbacks
  for (var i = 0; i < asyncQueue.length; i++)
    asyncQueue[i][0](asyncQueue[i][1]);

  // reset async asyncQueue
  asyncQueue = [];
  asyncTimer = false;
}

function asyncCall(callback, arg){
  asyncQueue.push([callback, arg]);

  if (!asyncTimer)
  {
    asyncTimer = true;
    asyncSetTimer(asyncFlush, 0);
  }
}


function invokeResolver(resolver, promise) {
  function resolvePromise(value) {
    resolve(promise, value);
  }

  function rejectPromise(reason) {
    reject(promise, reason);
  }

  try {
    resolver(resolvePromise, rejectPromise);
  } catch(e) {
    rejectPromise(e);
  }
}

function invokeCallback(subscriber){
  var owner = subscriber.owner;
  var settled = owner.state_;
  var value = owner.data_;  
  var callback = subscriber[settled];
  var promise = subscriber.then;

  if (typeof callback === 'function')
  {
    settled = FULFILLED;
    try {
      value = callback(value);
    } catch(e) {
      reject(promise, e);
    }
  }

  if (!handleThenable(promise, value))
  {
    if (settled === FULFILLED)
      resolve(promise, value);

    if (settled === REJECTED)
      reject(promise, value);
  }
}

function handleThenable(promise, value) {
  var resolved;

  try {
    if (promise === value)
      throw new TypeError('A promises callback cannot return that same promise.');

    if (value && (typeof value === 'function' || typeof value === 'object'))
    {
      var then = value.then;  // then should be retrived only once

      if (typeof then === 'function')
      {
        then.call(value, function(val){
          if (!resolved)
          {
            resolved = true;

            if (value !== val)
              resolve(promise, val);
            else
              fulfill(promise, val);
          }
        }, function(reason){
          if (!resolved)
          {
            resolved = true;

            reject(promise, reason);
          }
        });

        return true;
      }
    }
  } catch (e) {
    if (!resolved)
      reject(promise, e);

    return true;
  }

  return false;
}

function resolve(promise, value){
  if (promise === value || !handleThenable(promise, value))
    fulfill(promise, value);
}

function fulfill(promise, value){
  if (promise.state_ === PENDING)
  {
    promise.state_ = SEALED;
    promise.data_ = value;

    asyncCall(publishFulfillment, promise);
  }
}

function reject(promise, reason){
  if (promise.state_ === PENDING)
  {
    promise.state_ = SEALED;
    promise.data_ = reason;

    asyncCall(publishRejection, promise);
  }
}

function publish(promise) {
  var callbacks = promise.then_;
  promise.then_ = undefined;

  for (var i = 0; i < callbacks.length; i++) {
    invokeCallback(callbacks[i]);
  }
}

function publishFulfillment(promise){
  promise.state_ = FULFILLED;
  publish(promise);
}

function publishRejection(promise){
  promise.state_ = REJECTED;
  publish(promise);
}

/**
* @class
*/
function Promise(resolver){
  if (typeof resolver !== 'function')
    throw new TypeError('Promise constructor takes a function argument');

  if (this instanceof Promise === false)
    throw new TypeError('Failed to construct \'Promise\': Please use the \'new\' operator, this object constructor cannot be called as a function.');

  this.then_ = [];

  invokeResolver(resolver, this);
}

Promise.prototype = {
  constructor: Promise,

  state_: PENDING,
  then_: null,
  data_: undefined,

  then: function(onFulfillment, onRejection){
    var subscriber = {
      owner: this,
      then: new this.constructor(NOOP),
      fulfilled: onFulfillment,
      rejected: onRejection
    };

    if (this.state_ === FULFILLED || this.state_ === REJECTED)
    {
      // already resolved, call callback async
      asyncCall(invokeCallback, subscriber);
    }
    else
    {
      // subscribe
      this.then_.push(subscriber);
    }

    return subscriber.then;
  },

  'catch': function(onRejection) {
    return this.then(null, onRejection);
  }
};

Promise.all = function(promises){
  var Class = this;

  if (!isArray(promises))
    throw new TypeError('You must pass an array to Promise.all().');

  return new Class(function(resolve, reject){
    var results = [];
    var remaining = 0;

    function resolver(index){
      remaining++;
      return function(value){
        results[index] = value;
        if (!--remaining)
          resolve(results);
      };
    }

    for (var i = 0, promise; i < promises.length; i++)
    {
      promise = promises[i];

      if (promise && typeof promise.then === 'function')
        promise.then(resolver(i), reject);
      else
        results[i] = promise;
    }

    if (!remaining)
      resolve(results);
  });
};

Promise.race = function(promises){
  var Class = this;

  if (!isArray(promises))
    throw new TypeError('You must pass an array to Promise.race().');

  return new Class(function(resolve, reject) {
    for (var i = 0, promise; i < promises.length; i++)
    {
      promise = promises[i];

      if (promise && typeof promise.then === 'function')
        promise.then(resolve, reject);
      else
        resolve(promise);
    }
  });
};

Promise.resolve = function(value){
  var Class = this;

  if (value && typeof value === 'object' && value.constructor === Class)
    return value;

  return new Class(function(resolve){
    resolve(value);
  });
};

Promise.reject = function(reason){
  var Class = this;

  return new Class(function(resolve, reject){
    reject(reason);
  });
};

})(typeof window != 'undefined' ? window : typeof global != 'undefined' ? global : typeof self != 'undefined' ? self : this);

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}]},{},[3]);
